import React from "react"
import styled from "styled-components"
import iconPlaceholder from "../../images/icons/fundamentals.svg"

TopicCard.defaultProps = {
  preheading: "Learn",
  title: "Design fundamentals",
  paragraph: "Learn how to design the core aspects of a website.",
  linkUrl: "#",
  imageUrl: iconPlaceholder,
}

function TopicCard(props) {
  const { preheading, title, paragraph, linkUrl, imageUrl } = props

  return (
    <TopicCardContainer className="col-4 col-6-t col-12-m" href={linkUrl}>
      <TopicCardInner>
        <StyledPreheader>{preheading}</StyledPreheader>
        <StyledTitle>{title}</StyledTitle>
        <StyledParagraph>{paragraph}</StyledParagraph>
        <StyledImg src={imageUrl} />
      </TopicCardInner>
    </TopicCardContainer>
  )
}

export default TopicCard

// Styles
const TopicCardContainer = styled.a`
  background-color: ${props => props.theme.colors.lightbackground};
  color: ${props => props.theme.colors.primary};
  border-radius: 8px;
`

const TopicCardInner = styled.div`
  padding: 60px 40px;
  min-height: 360px;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`

const StyledPreheader = styled.p`
  font-size: 12px;
  font-weight: 600;
  color: ${props => props.theme.colors.white};
`

const StyledTitle = styled.h3`
  font-size: 30px;
  margin-top: 0;
  color: ${props => props.theme.colors.white};
`
const StyledParagraph = styled.p`
  max-width: 66%;

  @media only screen and (max-width: 768px) {
    max-width: 100%;
  }
`
const StyledImg = styled.img`
  margin-top: auto;
  align-self: flex-start;
`
