import React from "react"
import styled from "styled-components"

Button.defaultProps = {
  linkTitle: "Call to action",
  linkUrl: "#",
}

function Button(props) {
  const { linkTitle, linkUrl } = props

  return <ButtonContainer href={linkUrl}>{linkTitle}</ButtonContainer>
}

export default Button

const ButtonContainer = styled.a`
  background-color: ${props => props.theme.colors.orange};
  color: ${props => props.theme.colors.primary};
  border-radius: 8px;
  padding: 12px 20px;
  display: inline-flex;
  align-self: flex-start;
  transition: ${props => props.theme.transitions.all};

  &:hover {
    background-color: #daa48d;
  }
`
