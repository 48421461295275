import React from "react"
import Layout from "../components/template-components/layout"
import HomeHero from "../components/heros/HomeHero"
import BoxedContainer from "../components/common/BoxedContainer"
import TopicCard from "../components/cards/TopicCard"
import EmailSignup from "../components/email/EmailSignup"

const IndexPage = () => (
  <Layout>
    <HomeHero />
    <BoxedContainer>
      <TopicCard
        title="Design fundamentals"
        paragraph="Learn how to design the core aspects of a website."
      />
      <TopicCard
        title="Atomic design"
        paragraph="Learn how to create every part of common design patterns."
      />
      <TopicCard
        title="UX design"
        paragraph="Learn how to design with the user in mind."
      />
    </BoxedContainer>
    <BoxedContainer>
      <EmailSignup />
    </BoxedContainer>
  </Layout>
)

export default IndexPage
