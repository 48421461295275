import React from "react"
import styled from "styled-components"
import Button from "../common/Button"

HomeHero.defaultProps = {
  title: "Learn how to design.",
  paragraph:
    "Learning design doesn't have to be hard. That's why we made a straightforward guide on learning design.",
  linkTitle: "Get on the waiting list",
  linkUrl: "#",
}

function HomeHero(props) {
  const { title, paragraph, linkTitle, linkUrl } = props

  return (
    <HeroContainer className="boxed-width padding-bottom-large padding-top-large">
      <div>
        <HeroTitle>{title}</HeroTitle>
        <HeroParagraph>{paragraph}</HeroParagraph>
        <Button linkUrl={linkUrl} linkTitle={linkTitle} />
      </div>
    </HeroContainer>
  )
}

export default HomeHero

// Styles
const HeroContainer = styled.div``

const HeroTitle = styled.h1`
  font-size: 96px;
  font-weight: 600;
  color: ${props => props.theme.colors.white};
`

const HeroParagraph = styled.p`
  font-size: 20px;
  line-height: 1.5;
  color: ${props => props.theme.colors.textGrey};
  max-width: 488px;
  margin-bottom: 40px;
`
